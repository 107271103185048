button {
    width: 100%;
    height: 100%;
    border-style: none;
    background-color: transparent;
    text-align: center;
    word-wrap: break-word;
}

h1 {
    width: 100%;
    padding-bottom: 32px;
    text-align: center;
}

.row {
    flex-wrap: nowrap !important;
}

.square {
    margin: 5px;
    border: 2px solid black;
    border-radius: 16px;
    height: 20vw;
    max-height: 10rem;
    width: 20% !important;
}

.btn-active {
    background-color: #fa6607;
    
}
